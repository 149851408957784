<script setup>
/* Imports */
import {
  computed,
  ref,
  onBeforeMount,
  onMounted,
} from 'vue';
import VueCookies from 'vue-cookies';

/* Analytics */
import { sendAnalyticsEvent } from '../utils/ga/analytics';

/* Helpers */
import { getComponentFromList } from '../utils/mainUtils';

/* Composables */
import { useGetComponents } from '../composables/useGetComponents';

/* Props */
const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  locale: {
    type: String,
    default: '',
  },
  buyServiceBase: {
    type: String,
    default: '',
  },
  publicPath: {
    type: String,
    default: '',
  },
});

/* Initialize Composables */
const { getComponents } = useGetComponents();

/* State */
const state = ref({
  componentList: getComponents(props.content),
});

/* Lifecycle Hooks -> onBeforeMount */
onBeforeMount(() => {
  const pcQueryParam = new URL(window.location.href).searchParams.get('pc');

  // Another way buy uses promotions passed in by query param ?pc=<promo>
  if (pcQueryParam) VueCookies.set('GarminBuyPC', pcQueryParam, '0', '/', '.garmin.com', null, null, null);
});

/* Lifecycle Hooks -> onMounted */
onMounted(() => {
  window.sendAnalyticsEvent = sendAnalyticsEvent;
});

/* Computed */
const references = computed(() => props.content?.references);

/* Methods */
const getComponent = (reference) => getComponentFromList(reference, state.value?.componentList);
</script>

<template>
  <main>
    <component
      :is="{...getComponent(reference)}"
      v-for="reference in references"
      :key="reference?.id"
      :content="reference"
      :locale="props.locale"
      :buy-service-base="props.buyServiceBase"
      :public-path="props.publicPath"
    />
  </main>
</template>
