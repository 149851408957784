/**
 * Finds a component in a list based on its label.
 * @param {string} label        - The label to search for.
 * @param {Array} componentList - The list of components with label and component keys.
 * @returns {object | undefined}  - The component with the matching label or undefined if not found.
 * @example
 * const components = [{ label: 'heading', component: {} }, { label: 'copy', component: {} }];
 * findComponentByLabel('heading', components);
 * --> Returns the component object corresponding to 'heading'.
 */
export const findComponentByLabel = (label, componentList) => componentList?.find((c) => c?.label === label)?.component;

/**
 * Gets a component from a list based on a reference object.
 * @param {object} reference    - The reference object containing information about the component to retrieve.
 * @param {Array} componentList - The list of components to search through.
 * @returns {object | false}    - The component with the matching criteria or false if not found.
 * @example
 * const referenceWithDisplayType = {
 *   fields: {
 *     displayType: true,
 *     type: 'heading'
 *   }
 * };
 * const components = [{ label: 'heading', component: {} }];
 * getComponentFromList(referenceWithDisplayType, components);
 * --> Returns the component object corresponding to 'heading' based on displayType criteria.
 *
 * const referenceWithSysId = {
 *   sys: {
 *     contentType: {
 *       sys: {
 *         id: 'copy'
 *       }
 *     }
 *   }
 * };
 * getComponentFromList(referenceWithSysId, components);
 * --> Returns the component object corresponding to 'copy' based on sys.contentType.sys.id criteria.
 */

export const getComponentFromList = (reference, componentList) => {
  if (reference?.fields?.displayType) {
    // Use it to find the component by label
    return findComponentByLabel(reference?.fields?.type, componentList);
  }
  if (reference?.sys?.contentType?.sys?.id) {
    // Use it to find the component by label
    return findComponentByLabel(reference?.sys?.contentType?.sys?.id, componentList);
  }
  // If no matching criteria found, return false
  return false;
};
