/* Imports */
import { sanitizeAnalyticsString } from '@garmin/analytics';
import { mapGetters } from '../utils/mainUtils';
import { useRoute } from 'vue-router';

export const useGenericAnalyticsDataHelper = () => {
  /* Store - Getters */
  const {
    getCustomerGroups: customerGroups,
  } = mapGetters();

  /* Routing */
  const route = useRoute();

  /**
   * Generates analytics data.
   * @returns {object}               - The analytics data object.
   */
  const genericAnalyticsData = () => ({
    affiliation: window?.globalAnalyticsData?.affiliation || 'NA',
    country_code: window?.globalAnalyticsData?.country_code || 'NA',
    language_code: window?.globalAnalyticsData?.language_code || 'NA',
    page_name: sanitizeAnalyticsString(document?.title) || 'NA',
    page_type: window?.globalAnalyticsData?.page_type || 'NA',
    currency_code: window?.globalAnalyticsData?.currency_code || 'NA',
    login_status: window?.globalAnalyticsData?.login_status || 'NA',
    employee_flag: customerGroups?.value === 'US' ? 'true' : 'false',
    url_clean: window?.location?.host || 'NA',
    cm_source: route?.query?.utm_source || 'NA',
    cm_medium: route?.query?.utm_medium || 'NA',
    cm_campaign: route?.query?.utm_campaign || 'NA',
    cm_term: route?.query?.utm_term || 'NA',
    cm_content: route?.query?.utm_content || 'NA',
    page_category: 'Category',
    product_availability: window?.globalAnalyticsData?.product_availability || 'NA',
    sale_type: window?.globalAnalyticsData?.sale_type || 'NA',
    in_stock: window?.globalAnalyticsData?.in_stock || 'NA',
  });

  return { genericAnalyticsData };
};
